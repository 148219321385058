<script>
import sbSetupSorgenteDati from "@/components/Datarete/sbSetupSorgenteDati.vue";
import sbSelezioneSorgente from "@/components/Datarete/sbSelezioneSorgente.vue";
export default {
  components: { sbSetupSorgenteDati, sbSelezioneSorgente },
};
</script>

<template>
  <div class="user-page">
    <h1>Pagina test - 3</h1>

    <div
      class="w-full flex flex-column justify-content-start align-items-start p-4"
    >
      <b-button v-b-toggle.sidebar-setup class="mb-4">
        Setup Sorgente Dati
      </b-button>

      <b-button v-b-toggle.sidebar-selezione>
        Selezione Sorgente Dati
      </b-button>
    </div>

    <b-sidebar
      class="right-sidebar"
      id="sidebar-setup"
      right
      shadow
      lazy
      @hidden="resetSidebar"
      style="z-index:901 !important"
    >
      <sbSetupSorgenteDati />
    </b-sidebar>
    <b-sidebar
      class="right-sidebar"
      id="sidebar-selezione"
      right
      shadow
      lazy
      @hidden="resetSidebar"
      style="z-index:901 !important"
    >
      <sbSelezioneSorgente />
    </b-sidebar>
  </div>
</template>
